/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const $body = $('body');

        /*
		|
		| Modal Newsletter
		|-----------------
		*/
        if ($('.mkx-modal-loop').length) {
            $('.mkx-modal-loop').iziModal({});
        }


        $(document).ready(function () {

            if (window.location.href.indexOf("newsletter") > -1) {
                jQuery('#modnews').iziModal('open');
            }

            // if (document.cookie.indexOf("visited=") >= 0) {
            //     console.log('cookie présent');
            // } else {
            //     if (window.location.href.indexOf("newsletter") > -1) {
            //         var expiry = new Date();
            //         expiry.setTime(expiry.getTime() + (1000 * 60 * 60 * 24 * 3)); /* 3 days */
            //         document.cookie = "visited=yes; expires=" + expiry.toGMTString();
            //         jQuery('#modnews').iziModal('open');
            //         console.log('newsletter présent + ajout du cookie');
            //     } else {
            //         console.log('newsletter absent');
            //     }
            // }


        })


        /*
		|
		| FAQ
		|-----------------
		*/
        const items = document.querySelectorAll(".accordion button");

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (var i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }

            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');

                $('html, body').animate({
                    scrollTop: $(this).offset().top - 100
                }, 1000);

            }
        }

        items.forEach(item => item.addEventListener('click', toggleAccordion));


        /*
		|
		| Slider A propos
		|-----------------
		*/
        var swiperhome = new Swiper('#swiper-container', {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 1000,
            slidesPerView: 1,
            loopedSlides: 50,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 15
                },
            }
        });

        /*
		|
		| Tabs
		|-----------------
		*/
        $('#tabs ul.tabs li').click(function () {
            var tab_id = $(this).attr('data-tab');

            $('#tabs ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');

            $(this).addClass('current');
            $("#" + tab_id).addClass('current');
        })


        /*
		|
		| Filter list solutions
		|-----------------
		*/
        const filters = document.querySelectorAll('.filter');

        filters.forEach(filter => {

            filter.addEventListener('click', function () {

                let selectedFilter = filter.getAttribute('data-filter');
                let itemsToHide = document.querySelectorAll(`.list-filter .card-filter:not([data-filter='${selectedFilter}'])`);
                let itemsToShow = document.querySelectorAll(`.list-filter [data-filter='${selectedFilter}']`);

                if (selectedFilter == 'all') {
                    itemsToHide = [];
                    itemsToShow = document.querySelectorAll('.list-filter [data-filter]');
                }

                itemsToHide.forEach(el => {
                    el.classList.add('hide');
                    el.classList.remove('show');
                });

                itemsToShow.forEach(el => {
                    el.classList.remove('hide');
                    el.classList.add('show');
                });


                $('.filters .filter').removeClass('active');
                $(this).addClass('active');

            });
        });

        /*
		|
		| menu scroll
		|-----------------
		*/
        $("a[href*='#']:not([href='#'])").click(function () {
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
                if (anchor.length) {
                    $("html, body").animate({scrollTop: anchor.offset().top - 100}, 1500);
                }
            }
        });


        /*
		|
		| menu scroll
		|-----------------
		*/
        var lastScrollTop = 0;
        $(window).scroll(function () {
            var st = $(this).scrollTop();
            var banner = $('#header');
            setTimeout(function () {
                if (st > lastScrollTop) {
                    if ($(this).scrollTop() > 89) {
                        banner.addClass('hide');
                    }
                } else {
                    banner.removeClass('hide');
                }
                lastScrollTop = st;
            }, 100);
        });


        /*
		|
		| Sous menu
		|-----------------
		*/
        $(".menu-solution").on("mouseover", function () {
            $("header").find(".sub-menu-solution").stop().slideDown();
        });
        $(".sub-menu-solution").on("mouseover", function () {
            $("header").find(".sub-menu-solution").stop().slideDown();
        });

        $(".menu-solution").on("mouseout", function () {
            $("header").find(".sub-menu-solution").stop().slideUp();
        });
        $(".sub-menu-solution").on("mouseout", function () {
            $("header").find(".sub-menu-solution").stop().slideUp();
        });


        /*
		|
		| Hover header home
		|-----------------
		*/
        $(".link-banner .item").on("mouseover", function () {
            $(this).parent().find('.item').addClass('inactive');
            $(this).removeClass('inactive');
        });
        $(".link-banner .item").on("mouseout", function () {
            $(this).parent().find('.item').removeClass('inactive');
            $(this).removeClass('inactive');
        });


        /*
		|
		| Title slidedown
		|-----------------
		*/
        var buttonAccor = jQuery('.why-title');
        var sliderAccor = jQuery('.why-txt');

        buttonAccor.on('click', function (e) {
            if (sliderAccor.hasClass('closed')) {
                buttonAccor.toggleClass('active');
                sliderAccor.toggleClass('closed');
                sliderAccor.stop().slideDown();
            } else {
                buttonAccor.toggleClass('active');
                sliderAccor.toggleClass('closed');
                sliderAccor.stop().slideUp();
            }
        });


        /*
		|
		| Selecteur filtre mobil News
		|-----------------
		*/
        var button = $('.selecteur');
        var slider = $('.filter-mobil');

        button.on('click', function (e) {
            if (slider.hasClass('closed')) {
                button.toggleClass('active');
                slider.toggleClass('closed');
                slider.stop().slideDown();
            } else {
                button.toggleClass('active');
                slider.toggleClass('closed');
                slider.stop().slideUp();
            }
        });


        /*
		|
		| Retour à la ligne home slider
		|-------------------------------
	    */
        $(".link3").html(function () {
            var text = $(this).text().trim().split(" ");
            var first = text.shift();
            return (text.length > 0 ? "<span class='firstword'>" + first + "</span> " : first) + text.join(" ");
        });

        // $body.on('loaderEnd', () => console.log('ended'))
    }
}
