/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        console.log('comprendre.js')

        $('.js-input-filter').on('keyup', (e) => {
            const search =$(e.currentTarget).val()

            $('.js-title').each(function (index, el) {
                const regex = new RegExp(`${search}`, "gi")
                const str = $(this).text()          

                if(regex.test(str)) {
                    $(el).parent().removeClass('hide').addClass('match')
                } else {
                    $(el).parent().addClass('hide').removeClass('match')
                }
            })

            $('.js-subtitle').each(function (index, el) {
                const regex = new RegExp(`${search}`, "gi")
                const str = $(this).text()          

                if(regex.test(str)) {
                    $(el).removeClass('hide')
                    $(el).parent().parent().removeClass('hide')
                    $(el).closest('.js-group').removeClass('hide')
                } else {
                    if($(el).closest('.js-group').hasClass('match')) {
                        $(el).removeClass('hide')
                        $(el).parent().parent().removeClass('hide')
                    } else {
                        $(el).parent().parent().addClass('hide')
                        $(el).addClass('hide')
                    }
                }
            })
        })
    }
}
